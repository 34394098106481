import React from "react";
import "./style.scss";

const SendgridSignupForm = ({ urlDesktop, urlMobile }) => {
  return (
    <div className="">
      <div className=" d-block d-md-none">
        <iframe
          scrolling="no"
          className="c-sendgrid-signup-form c-sendgrid-signup-form--mobile"
          src={urlMobile}
        />
      </div>
      <div className="d-none d-md-block">
        <iframe
          scrolling="no"
          className="c-sendgrid-signup-form c-sendgrid-signup-form--desktop"
          src={urlDesktop}
        />{" "}
      </div>
    </div>
  );
};

export default SendgridSignupForm;
