import React from "react";
import Layout from "../../components/layout";
import Banner from "../../components/banner";
import HtmlContent from "../../components/html-content";
import SendgridSignupForm from "../../components/notify-me-form/sendgridSignupForm";
import { graphql } from "gatsby";

// eslint-disable-next-line no-unused-vars
import * as PageSeoFragment from "../../fragments/pageSeo";
import wrapWithLayout from "../../components/layout/wrapWithLayout";
import ContactForm from "../../components/contactForm";

const CollaborationPage = wrapWithLayout(({ data }) => {
  const { strapiCollaboratePagePublic } = data;
  const banner = strapiCollaboratePagePublic?.banner;
  const text = strapiCollaboratePagePublic?.text;
  const product = strapiCollaboratePagePublic?.product;
  const embedded_signup_form = product?.embedded_signup_form;

  return (
    <>
      {banner && <Banner {...banner} />}
      <div className="container">
        <div className="mt-4">
          {text && <HtmlContent content={text.data} />}
        </div>
        <div id="contact">{product && <ContactForm product={product} />}</div>
      </div>
    </>
  );
});

export const query = graphql`
  query {
    strapiCollaboratePagePublic {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      product {
        ...ProductFragment
      }
      text {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default CollaborationPage;
